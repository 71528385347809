.toggle {
    cursor: pointer;
    font: inherit;
    background-color: transparent;
    border: none;
  }

.toggle:hover {
    background-color: transparent;
    color: #ae82cc;
  }

.toggle:focus {
    outline: none;
  }

.invalid-input{
    border: 1px solid #b40e0e;
    background-color: #fddddd;
  }
  
.error-text {
    color: #b40e0e;
  }

